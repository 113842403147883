.purchasedBlockContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    margin: 15px 25px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
    border-radius: 5px;
    overflow: auto;
}

.blockTitleRow{
    display: flex;
    flex-direction: row;
}
.tableRow {
   text-decoration: none;
}

.tableRowHover:hover {
   cursor: pointer;
   background-color: #f4f4f4;
}

.table {
   font-size: 0.875rem;
   overflow: auto;
}

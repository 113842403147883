.tableBlockTitle{
    font-size: 1.25rem;
    font-family: "Roboto";
    font-weight: 500;
    color: #1976d2;

    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 15px;
}

.tableRowsSelected{
    margin-right: auto;
    margin-left: 15px;
}
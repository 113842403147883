.counter{
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.85rem;

    min-width: 176px;
}

.counterNumber{
    color: black;
    font-size: 1.5rem;
}
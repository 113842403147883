.blockContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin: 15px 25px;
    height: 90px;

    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
    border-radius: 5px;
}
.container {
   display: flex;
   flex-direction: row;
   margin-right: 5px;
}

.button {
   display: flex;
   flex-direction: column;
   margin-left: 10px;
   margin-right: 10px;
   color: grey;
   position: relative;
   align-items: center;
}

.button:hover {
   cursor: pointer;
}

.tooltipText {
   visibility: hidden;
   position: absolute;
   justify-content: center;
   font-size: small;
   margin-top: 30px;
   margin-left: 10%;
}

.button:hover .tooltipText {
   visibility: visible;
}

.searchBar {
   width: 100%;
}

.searchCheckBoxes button {
   margin-right: 15px;
   border-radius: 25px;
   border: 1px solid rgba(27, 31, 35, 0.15);

   cursor: pointer;
}

.filterSelected {
   background-color: #1976d2;
   color: white;
}

.filterNotSelected {
   background-color: white;
   color: #1976d2;
}
